'use client';

import { useEffect } from 'react';

import { Locale } from '../Entities/Locale/Locale.entity';
import { loadGoogleRecaptcha } from '../Utils/loadGoogleRecaptcha';

export const useGoogleRecaptcha = (locale: Locale) => {
  useEffect(() => {
    const loadScript = async () => {
      try {
        await loadGoogleRecaptcha(locale);
      } catch (error) {
        console.error(error);
      }
    };
    loadScript();
  }, []);
};
