import Checkbox from '@gds/Checkbox/Checkbox';
import { Typography } from '@gds/Typography/Typography';
import { useOptIn } from '@lead-forms/Hooks/UseOptIn';

import styles from './MarketingOptInCheckbox.module.css';

interface Props {
  strings: Record<string, string>;
  isMarketingOptInChecked: boolean;
  disabled: boolean;
  inputProps: {
    id: string;
    handleChange: (field: string, value: boolean) => void;
  };
}
export const MarketingOptInCheckbox = ({
  strings: { marketingOptIn },
  inputProps,
  isMarketingOptInChecked,
  disabled,
}: Props) => {
  const { optInData } = useOptIn();
  const { id: marketingOptInsId, handleChange } = inputProps;

  // On submit, if isMarketingOptInChange is true, use setOptIn to opt the user into marketing for their relevant market
  // const { optInData, setOptIn } = useOptIn();

  return (
    <div>
      {!optInData.email && (
        <div className={styles.marketingOptIn}>
          <label htmlFor="marketeting-optin" aria-labelledby="marketeting-optin">
            <Checkbox
              id="marketeting-optin"
              checked={isMarketingOptInChecked}
              onChange={() => null}
              onClick={() => handleChange(marketingOptInsId, !isMarketingOptInChecked)}
              disabled={!!disabled}
            />
            <Typography variant="body3" Component="span">
              {marketingOptIn}
            </Typography>
          </label>
        </div>
      )}
    </div>
  );
};
