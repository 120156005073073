import { useAtom } from 'jotai';

import { optInAtom } from '../Atoms/OptIn.atom';

export const useOptIn = () => {
  const [optInData, setOptInData] = useAtom(optInAtom);

  const setOptIn = (email: string) => {
    setOptInData({ email: Boolean(email) });
  };

  return { optInData, setOptIn };
};
