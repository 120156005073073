import { commonApiURL, ukApiURL } from '@core/Environment/Variables';

import { OptInKeys, OptInUrls } from '../Entities/OptIn.entities';

export const OPT_IN_URLS: OptInUrls = {
  uk: `${ukApiURL}/opt-in/`,
  de: `${commonApiURL}/subscriptions/newsletter`,
};
export const optInKeys: OptInKeys = {
  uk: 'hc_marketing_opt_in',
  fr: 'hc_marketing_opt_in_fr',
  de: 'hc_marketing_opt_in_de',
};
