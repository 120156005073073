import { atomWithStorage } from 'jotai/utils';

import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { Locale } from '@core/Entities/Locale/Locale.entity';

import { optInKeys } from '../Constants/OptIn.constants';
import { OptInData } from '../Entities/OptIn.entities';

const locale = getClientSideCookieValue<Locale>('locale') ?? 'uk';
export const optInAtom = atomWithStorage<OptInData>(optInKeys[locale] ?? optInKeys.uk, {});
