'use client';

import { atomWithStorage } from 'jotai/utils';

import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { VehicleValuationData } from '@core/Entities/Vehicle/Valuation.entity';

import { valuationKeys } from '../Constants/Valuation.constants';

const locale = getClientSideCookieValue<Locale>('locale') ?? 'uk';

const valuationAtom = atomWithStorage<VehicleValuationData | undefined | null>(
  valuationKeys[locale] ?? valuationKeys.uk!,
  undefined,
);

export { valuationAtom };
