import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { Accordion } from '@gds/Accordion/Accordion';

import { useAtomValue } from 'jotai';

import { similarVehiclesAtom } from 'Atoms/App/Pdp/SimilarVehicles.atom';

import { OneClickCheckbox } from '../OneClickCheckbox/OneClickCheckbox';
import { DriftrockCheckbox } from '../OneClickDriftrockCheckbox/DriftrockCheckbox';
import { OneClickDriftrockCheckbox } from '../OneClickDriftrockCheckbox/OneClickDriftrockCheckbox';

import { useDriftrockCheckbox } from '../OneClickDriftrockCheckbox/UseDriftrockCheckbox';

import { MultiLeadCheckboxesProps } from './MultiLeadCheckboxes.entity';

import { getMultiLeadCheckboxFields } from './MultiLeadCheckboxes.utils';
import { MultiLeadCheckboxesSkeleton } from './MultiLeadCheckboxesSkeleton';

import styles from './MultiLeadCheckboxes.module.css';

export const MultiLeadCheckboxes = ({
  formFields,
  handleChange,
  strings,
  values,
}: MultiLeadCheckboxesProps) => {
  const { oneClickCheckboxFields, oneClickDriftrockCheckboxFields, driftrockCheckboxFields } =
    getMultiLeadCheckboxFields(formFields);
  const similarVehicles = useAtomValue(similarVehiclesAtom);
  const currentVehicles = useAtomValue(currentVehicleAtom);

  const isSimilarVehiclesEmpty = Array.isArray(similarVehicles) && similarVehicles.length === 0;
  const product = useDriftrockCheckbox({
    vehicle: currentVehicles,
    locale: 'uk',
    hasSimilarVehicles: !isSimilarVehiclesEmpty,
  });
  if (similarVehicles === undefined) return <MultiLeadCheckboxesSkeleton />;
  if (
    (isSimilarVehiclesEmpty || similarVehicles === null) &&
    (!driftrockCheckboxFields || !product)
  )
    return null;

  return (
    <Accordion title={strings.similarCarsHeader} open className={styles.acrdn}>
      {/* NOTE: OneClickCheckbox & OneClickDriftrockCheckbox will never show together */}
      {/* OneClickDriftrockCheckbox & DriftrockCheckbox will never show together */}
      {oneClickCheckboxFields && (
        <OneClickCheckbox
          strings={strings}
          isChecked={!!values.oneClickChkBox}
          inputProps={{
            id: oneClickCheckboxFields.id,
            handleChange,
          }}
          similarVehicles={similarVehicles}
        />
      )}
      {oneClickDriftrockCheckboxFields && (
        <OneClickDriftrockCheckbox
          strings={strings}
          isChecked={!!values.oneClickDriftrockChkBox}
          inputProps={{
            id: oneClickDriftrockCheckboxFields.id,
            handleChange,
          }}
          similarVehicles={similarVehicles}
        />
      )}
      {driftrockCheckboxFields && (
        <DriftrockCheckbox
          isChecked={!!values.driftrockChkBox}
          inputProps={{
            id: driftrockCheckboxFields.id,
            handleChange,
          }}
          hasSimilarVehicles={!isSimilarVehiclesEmpty}
        />
      )}
    </Accordion>
  );
};

MultiLeadCheckboxes.displayName = 'MultiLeadCheckboxes';
