import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { currentVehicleIdAtom } from '@core/Atoms/Vehicle/CurrentVehicleId.atom';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import Button from '@gds/Button/Button';
import { Typography } from '@gds/Typography/Typography';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { closeModalAtom } from '@modal/Atoms/Modal.atom';
import { ReserveNowBadge } from '@shared-components/ReserveNowBadge/ReserveNowBadge';
import { ReserveNowHowItWorks } from '@shared-components/ReserveNowHowItWorks/ReserveNowHowItWorks';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtomValue, useSetAtom } from 'jotai';
import Link from 'next/link';

import { generateReservationTrackingData } from './ReservationCheckoutCta.tracking';

import styles from './ReservationCheckoutCta.module.css';

interface ReservationCheckoutCtaProps {
  strings: Record<string, string>;
  locale: Locale;
}

export const ReservationCheckoutCta = ({ strings, locale }: ReservationCheckoutCtaProps) => {
  const closeModal = useSetAtom(closeModalAtom);
  const userId = useAtomValue(userIdAtom);
  const formState = useAtomValue(formStateAtom);
  const listingId = useAtomValue(currentVehicleIdAtom);
  const vehicle = useAtomValue(currentVehicleAtom)!;
  const searchParams = useAtomValue(searchParamsAtom);
  const flags = useAtomValue(flagsAtom);

  const handleClick = () => {
    const {
      reserveStart: { event, context },
    } = generateReservationTrackingData({
      searchParams,
      userId,
      formState,
      listingId,
      vehicle,
    });
    trackCustomEvent({ event, context });
    closeModal();
  };

  const oldDesign = (
    <>
      <Typography variant="h6" className={styles.title}>
        {strings.reservationTitle}
      </Typography>
      <div className={styles.content}>
        <Typography variant="subheading4" className={styles.subtitle}>
          {strings.reservationSubtitle}
        </Typography>
        <Link
          legacyBehavior
          href={`/${locale}/checkout/${vehicle?.heycarId}/reservation/about-you`}
          className={styles.buyOnlineCTA}
        >
          <Button variant="outlined" className={styles.button} onClick={handleClick}>
            {strings.reservationCta}
          </Button>
        </Link>
      </div>
    </>
  );

  const newDesign = (
    <>
      <div className={styles.titleWrapper}>
        <Typography variant="h6" className={styles.title}>
          {strings.reserveNowDontMissOut}
        </Typography>
        <ReserveNowBadge desktopString={strings.newFeature} mobileString={strings.new} />
      </div>
      <div className={styles.content}>
        <ReserveNowHowItWorks strings={strings} />
        <Link
          legacyBehavior
          href={`/${locale}/checkout/${vehicle?.heycarId}/reservation/about-you`}
          className={styles.buyOnlineCTA}
        >
          <Button
            data-test-id="reserveNowCta"
            variant="outlined"
            className={styles.button}
            onClick={handleClick}
          >
            {strings.reservationCta}
          </Button>
        </Link>
      </div>
    </>
  );

  return (
    <div className={styles.container}>{flags.reserveNowNewFeature ? newDesign : oldDesign}</div>
  );
};
