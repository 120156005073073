/* eslint-disable @typescript-eslint/naming-convention */

'use client';

import cookies from 'js-cookie';

import { Locale } from '@core/Entities/Locale/Locale.entity';

import { fetchJson } from '@core/Utils/Fetch/FetchJson';

import { RECENTLY_VIEWED_URL } from './RecentlyViewed.constants';
import { RecentlyViewedList } from './RecentlyViewed.entity';

export const removeRecentlyViewedVehicle = async (
  vehicleId: string,
  locale: Locale,
): Promise<RecentlyViewedList> => {
  const recentlyViewedCookie = cookies.get(`recentlyViewed-${locale}`);
  const rawAuthToken = cookies.get('authToken');
  const authToken = rawAuthToken ? JSON.parse(rawAuthToken) : null;

  let currentList: RecentlyViewedList = recentlyViewedCookie
    ? JSON.parse(recentlyViewedCookie)
    : [];

  // Remove existing vehicle with same ID if present
  currentList = currentList.filter(item => item.vehicleId !== vehicleId);

  if (authToken) {
    const headers = {
      Authorization: `Bearer ${authToken}`,
      'x-heycar-tenant': locale,
      'x-heycar-site': 'heycar',
    };

    const body = { currentList };

    let consolidatedList: RecentlyViewedList = [];

    try {
      consolidatedList = await fetchJson<RecentlyViewedList>(RECENTLY_VIEWED_URL, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      });
    } catch (error) {
      cookies.set(`recentlyViewed-${locale}`, JSON.stringify(currentList));
      return currentList;
    }

    const finalList = consolidatedList.length < currentList.length ? currentList : consolidatedList;

    cookies.set(`recentlyViewed-${locale}`, JSON.stringify(finalList));

    return finalList;
  }

  // Limit list to 10 items
  if (currentList.length > 10) currentList = currentList.slice(0, 10);

  cookies.set(`recentlyViewed-${locale}`, JSON.stringify(currentList));

  return currentList.slice(0, 10);
};
