import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';

export const getVehicleFieldsProductOffering = (vehicleData: Vehicle | null) => {
  try {
    if (!vehicleData || typeof vehicleData !== 'object') {
      throw new Error('Invalid vehicle data provided');
    }

    return {
      vehicle: {
        spec: {
          bodyType: vehicleData.spec?.bodyType || '',
          fuelType: vehicleData.spec?.fuelType || '',
        },
        heycarId: vehicleData.heycarId || '',
        model: {
          id: vehicleData.model?.id || '',
        },
        details: {
          lastUpdatedAt: vehicleData.details?.lastUpdatedAt || '',
        },
        make: {
          id: vehicleData.make?.id || '',
        },
      },
    };
  } catch (error) {
    console.error('Error extracting vehicle fields:', error.message);
    return null;
  }
};
