import { Calculation } from '../Entities/Calculation.entity';

export const isStandardFinancing = (calculation: Calculation | null) =>
  calculation?.selectedProduct === 'standardFinancing';

export const isLeasingFinancing = (calculation: Calculation | null) =>
  calculation?.selectedProduct === 'privateLeasing';

export const isThreeWayFinancing = (calculation: Calculation | null) =>
  calculation?.selectedProduct === 'threeWayFinancing';

export const isTwoWayFinancing = (calculation: Calculation | null) =>
  calculation?.selectedProduct === 'twoWayFinancing';
