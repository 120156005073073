'use client';

import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { PreApprovalConfig } from '../../Entities/Config/PreApprovalConfig.entity';
import { sessionStorageHelper } from '../../Utils/SessionStorageHelper';

export const preApprovalConfigAtom = atomWithStorage<PreApprovalConfig | null>(
  'preApprovalConfig',
  null,
  sessionStorageHelper as any,
);

export const resetPreApprovalConfigAtom = atom(null, (_get, set) => {
  set(preApprovalConfigAtom, null);
});
