import { useEffect, useState } from 'react';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { fetchJson } from '@core/Utils/Fetch/FetchJson';
import { ProductDetail } from '@lead-forms/Entities/Product.entity';
import { eligibleProductAtom } from '@lead-forms-back-up/Atoms/EligibleProduct.atom';
import { useAtom } from 'jotai';

import { getVehicleFieldsProductOffering } from '../Utils/getVehicleFieldsProductOffering';

export const useDriftrockCheckbox = ({
  vehicle,
  locale,
  hasSimilarVehicles,
}: {
  vehicle: Vehicle | null;
  locale: Locale;
  hasSimilarVehicles: boolean;
}) => {
  const [_, setEligibleProduct] = useAtom(eligibleProductAtom);
  const [eP, setEP] = useState<ProductDetail | undefined>(undefined);
  const vehiclePayload = getVehicleFieldsProductOffering(vehicle);
  const getEligibility = async () => {
    const data = await fetchJson<{ product: ProductDetail }>(
      `${process.env.NEXT_PUBLIC_COMMON_API_URL}/product-offering/eligibility-check/details`,
      {
        headers: {
          'x-heycar-tenant': locale,
        },
        method: 'POST',
        body: JSON.stringify({
          vehicle: vehiclePayload?.vehicle,
          productCodes: vehicle!.eligibleProducts.filter((product: string) =>
            product.includes('DRIFTROCK_'),
          ),
          hasSimilarVehicles,
        }),
      },
    );

    if (!data) {
      throw new Error();
    }

    if (data.product && data.product.state === 'MATCHED') {
      setEligibleProduct(data.product);
      setEP(data.product);
    }
  };

  useEffect(() => {
    if (vehicle) getEligibility();
  }, [vehicle]);

  return eP;
};
