import { useEffect, useState } from 'react';

import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { capitalCase } from '@core/Utils/Strings/CapitalCase';
import { experimentsAtom } from '@growthBookExperimentation/Atoms/Experiments.atom';

import { useAtomValue } from 'jotai';

import { LeadType } from 'Components/Pdp/LeadCTAs/LeadCTAs.entity';

import { leadModalHeaderText } from '../Utils/Leadforms.util';

export type LeadTypeToMessageMap = Record<LeadType, string>;

type LeadFormHeaderTextExperimentState = {
  header: string;
  leadTypeToMessageMap: LeadTypeToMessageMap;
};

/**
 * Custom hook to determine the header text for lead forms based on the lead type and vehicle information.
 * It also supports an experiment to include dealer names in the header text.
 *
 * @param {LeadType | LeadType[]} leadType - The type of lead or an array of lead types.
 * @param {Vehicle | null | undefined} vehicle - The vehicle information, which may include dealer details.
 * @param {Record<string, string>} [allmessages] - An object containing message strings for different lead types.
 *
 * @returns {Object} An object containing the header text and a map of lead types to their corresponding messages.
 * @returns {string} return.header - The header text for the lead form.
 * @returns {LeadTypeToMessageMap} return.leadTypeToMessageMap - A map of lead types to their corresponding messages.
 */
export default function useLeadFormHeaderTextExperiment(
  leadType: LeadType | LeadType[],
  vehicle: Vehicle | null | undefined,
  allmessages: Record<string, string>,
): LeadFormHeaderTextExperimentState {
  const experiments = useAtomValue(experimentsAtom);

  const dealerNameExperiment = experiments.find(
    experiment => experiment.experimentId === 'dealername-leadform-experiment',
  );

  const isDealerNameExperimentEnabled = !!dealerNameExperiment;
  const variationId = isDealerNameExperimentEnabled ? dealerNameExperiment?.variationId : '0';

  const messages = { ...allmessages };

  const {
    bookAppointmentWith,
    messageDealer,
    messageDealerFinance,
    requestCallbackFrom,
    requestImagesFrom,
    requestVideosFrom,
    leasingOnlineHeader,
  } = messages;

  const newLeadTypeToMessageMap: LeadTypeToMessageMap = {
    'message-dealer': messageDealer,
    'message-dealer-finance': messageDealerFinance,
    'request-callback': requestCallbackFrom,
    'request-video': requestVideosFrom,
    'request-images': requestImagesFrom,
    'call-dealer': messageDealer,
    'book-appointment': bookAppointmentWith,
    'history-check': messageDealer, // Where do I find this lead form?
    'leasing-online': leasingOnlineHeader, // There is no dealer involved. Should it be 'Message heycar'?
  };

  const existingLeadTypeToMessageMap = {
    'message-dealer': leadModalHeaderText('message-dealer', messages),
    'message-dealer-finance': leadModalHeaderText('message-dealer-finance', messages),
    'request-callback': leadModalHeaderText('request-callback', messages),
    'request-video': leadModalHeaderText('request-video', messages),
    'request-images': leadModalHeaderText('request-images', messages),
    'call-dealer': leadModalHeaderText('call-dealer', messages),
    'book-appointment': leadModalHeaderText('book-appointment', messages),
    'history-check': leadModalHeaderText('history-check', messages),
    'leasing-online': leadModalHeaderText('leasing-online', messages),
  };

  const [state, setState] = useState<LeadFormHeaderTextExperimentState>({
    header: !Array.isArray(leadType) ? leadModalHeaderText(leadType, messages) : '',
    leadTypeToMessageMap: newLeadTypeToMessageMap,
  });

  useEffect(() => {
    let header = '';

    if (
      !isDealerNameExperimentEnabled ||
      !vehicle ||
      (isDealerNameExperimentEnabled && variationId === '0')
    ) {
      if (!Array.isArray(leadType)) {
        header = leadModalHeaderText(leadType, messages);
        existingLeadTypeToMessageMap[leadType] = leadModalHeaderText(leadType, messages);
      } else {
        header = leadModalHeaderText(leadType[0], messages);
        leadType.forEach(
          type => (existingLeadTypeToMessageMap[type] = leadModalHeaderText(type, messages)),
        );
      }
      setState(() => ({ header, leadTypeToMessageMap: existingLeadTypeToMessageMap }));
      return;
    }

    let dealerName = vehicle?.dealer?.name || vehicle?.dealer?.displayName || '';

    const isHeycarStockDealer = /\b(Exclusively delivered by heycar|Delivered By Heycar)\b/gi.test(
      dealerName,
    );

    dealerName = capitalCase(dealerName?.toLocaleLowerCase());

    if (checkIfHeyDekra(dealerName)) {
      dealerName = 'heycar';
    }

    const updatedLeadTypeToMessageMap = { ...newLeadTypeToMessageMap };

    updatedLeadTypeToMessageMap['leasing-online'] = messageDealer;

    if (isHeycarStockDealer) {
      if (!Array.isArray(leadType)) {
        header = `${updatedLeadTypeToMessageMap[leadType]} heycar`;
        updatedLeadTypeToMessageMap[leadType] = `${updatedLeadTypeToMessageMap[leadType]} heycar`;
      } else {
        header = `${updatedLeadTypeToMessageMap[leadType[0]]} heycar`;
        leadType.forEach(
          type =>
            (updatedLeadTypeToMessageMap[type] = `${updatedLeadTypeToMessageMap[type]} heycar`),
        );
      }
    } else {
      if (!Array.isArray(leadType)) {
        header = `${updatedLeadTypeToMessageMap[leadType]} ${dealerName}`;
        updatedLeadTypeToMessageMap[
          leadType
        ] = `${updatedLeadTypeToMessageMap[leadType]} ${dealerName}`;
      } else {
        header = `${updatedLeadTypeToMessageMap[leadType[0]]} ${dealerName}`;
        leadType.forEach(
          type =>
            (updatedLeadTypeToMessageMap[
              type
            ] = `${updatedLeadTypeToMessageMap[type]} ${dealerName}`),
        );
      }
    }

    setState(() => ({ header, leadTypeToMessageMap: updatedLeadTypeToMessageMap }));
  }, [vehicle, isDealerNameExperimentEnabled]);

  return state;
}

/**
 * Check if the dealer name contains 'Hey Dekra'.
 *
 * @param {string} dealerName - The name of the dealer.
 * @returns {boolean} Whether the dealer name contains 'Hey Dekra'.
 * @summary
 * regex: /\b(hey dekra)\b/gi
 *
 * \b - Word boundary
 * g - Global search
 * i - Case-insensitive search
 *
 */
function checkIfHeyDekra(dealerName: string): boolean {
  return /\b(hey dekra)\b/gi.test(dealerName);
}
