import {
  EMAIL_VALIDATION,
  FR_NUMBER_PLATE_REGEX,
  NUMBER_PLATE_REGEX,
  UK_POSTCODE_REGEX,
} from '@core/Constants/Validations';

import { InputItem } from 'Components/FormFields/FormFields.entity';

import { TITLE_OPTIONS } from '../../../Constants/LeadForm.constants';
import { defaultErrorMessage } from '../Utils/ErrorMessages';
import { checkMinDate, hasDriftrockProduct, isValidPhoneNumber } from '../Utils/Leadforms.util';

const MessageDealerFinanceFieldItems: InputItem[] = [
  {
    id: 'title',
    dataTestId: 'title',
    type: 'select',
    label: ({ titleLabel }) => titleLabel,
    width: () => 'double',
    options: () => TITLE_OPTIONS,
    isActive: ({ isFinCalcInteractedWith, locale, vehicle }) =>
      locale === 'fr' && isFinCalcInteractedWith && vehicle.dealer.fdlConditions === 'cgi_fr',
  },
  {
    id: 'firstName',
    dataTestId: 'firstName',
    type: 'input',
    autoComplete: 'on',
    label: ({ firstNameLabel }) => firstNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'lastName',
    dataTestId: 'lastName',
    type: 'input',
    autoComplete: 'on',
    label: ({ lastNameLabel }) => lastNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'email',
    dataTestId: 'email',
    type: 'input',
    label: ({ emailAddressLabel }) => emailAddressLabel,
    isValid: ({ value }) => EMAIL_VALIDATION.test(value),
    isActive: () => true,
    width: ({ flags }) => (flags.postcodeRequired ? 'full' : 'half'),
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'phone',
    dataTestId: 'phone',
    type: 'input',
    label: ({ phoneNumberLabel }) => phoneNumberLabel,
    isValid: ({ value }) => isValidPhoneNumber(value.trim()),
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'postcode',
    dataTestId: 'postcode',
    type: 'input',
    label: ({ postalCodeLabel }) => postalCodeLabel,
    isValid: ({ value }) => UK_POSTCODE_REGEX.test(value.trim()),
    isActive: ({ flags }) => !!flags.postcodeRequired,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
    defaultValue: ({ extraData }) => extraData?.postcode,
  },
  {
    id: 'message',
    dataTestId: 'message',
    type: 'textarea',
    label: ({ optionalMessageLabel }) => optionalMessageLabel,
    width: () => 'full',
    isActive: () => true,
  },

  {
    id: 'date',
    isValid: ({ value }) => checkMinDate(value),
    errorMessage: defaultErrorMessage,
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentBookAppointment && !!vehicle.pricing.package?.bookAppointment,
  },
  {
    id: 'preferredTime',
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentBookAppointment && !!vehicle.pricing.package?.bookAppointment,
  },
  {
    id: 'valuationChkBox',
    defaultValue: () => false,
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange &&
      !!vehicle.pricing.package?.messagePartX &&
      !!vehicle.pricing.package?.messageValuation,
  },
  {
    id: 'registration',
    errorMessage: defaultErrorMessage,
    isValid: ({ value }) =>
      // Returning true if value is empty [optional field]
      !value ? true : NUMBER_PLATE_REGEX.test(value) || FR_NUMBER_PLATE_REGEX.test(value),
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange && !!vehicle.pricing.package?.messagePartX,
  },
  {
    id: 'mileage',
    errorMessage: defaultErrorMessage,
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange && !!vehicle.pricing.package?.messagePartX,
  },
  {
    id: 'oneClickChkBox',
    defaultValue: () => false,
    isActive: ({ vehicle, flags }) =>
      flags.leadFormOneClickChkbox &&
      (!hasDriftrockProduct(vehicle) || flags.individualDriftrockChkbox),
  },
  {
    id: 'oneClickDriftrockChkBox',
    defaultValue: () => false,
    isActive: ({ vehicle, flags }) =>
      flags.leadFormOneClickDriftrockChkbox &&
      !flags.individualDriftrockChkbox &&
      hasDriftrockProduct(vehicle),
  },
  {
    id: 'driftrockChkBox',
    defaultValue: () => false,
    isActive: ({ vehicle, flags }) =>
      flags.leadFormOneClickDriftrockChkbox &&
      flags.individualDriftrockChkbox &&
      hasDriftrockProduct(vehicle),
  },
  {
    id: 'reserveVehicleChkBox',
    defaultValue: () => false,
    isActive: ({ flags }) => flags.leadFormReserveVehicleChkbox,
  },
  {
    id: 'marketingOptIns',
    defaultValue: () => false,
    isActive: () => true,
  },
  {
    id: 'financeQuoteChkBox',
    defaultValue: () => true,
    isActive: ({ vehicle, flags, isFinCalcInteractedWith }) =>
      (flags.leadEnrichmentFinanceQuote && !!vehicle.pricing.package?.messageFinance) ||
      isFinCalcInteractedWith,
  },
  {
    id: 'financeQuoteTcsChkBox',
    type: 'checkbox',
    defaultValue: ({ isFinCalcInteractedWith }) => !isFinCalcInteractedWith,
    isActive: ({ vehicle, flags, isFinCalcInteractedWith, locale }) =>
      locale === 'fr' &&
      ((flags.leadEnrichmentFinanceQuote && !!vehicle.pricing.package?.messageFinance) ||
        isFinCalcInteractedWith),
    isValid: ({ values }) => {
      return (
        (values['financeQuoteChkBox'] && values['financeQuoteTcsChkBox']) ||
        !values['financeQuoteChkBox']
      );
    },
    errorMessage: defaultErrorMessage,
  },
];

export { MessageDealerFinanceFieldItems };
