import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { SearchParamsType } from '@core/Atoms/SearchParams/SearchParams.atom';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';

import { TrackingDataType } from './Entities/TrackingDataType.entity';
import { hovStartToEvent } from './Schemas/Hov/HovStart/HovStartToEvent';
import { hovSubmitToEvent } from './Schemas/Hov/HovSubmit/HovSubmitToEvent';
import { vehicleToListingEvent } from './Schemas/Listing/VehicleToListingEvent';
import { vehicleToListingFeatureEvent } from './Schemas/ListingFeature/VehicleToListingFeatureEvent';
import { marketingToEvent } from './Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from './Schemas/UserContext/UserContextToEvent';

interface Props {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState: LeadFormState;
  vehicle: Vehicle;
}

export function generateHovTrackingData({
  searchParams,
  userId,
  formState,
  vehicle,
}: Props): Record<'hovStart' | 'hovSubmit', TrackingDataType> {
  const marketingDataObj = {
    marketingValues: {
      ...searchParams,
    },
  };

  const userData = {
    userId,
    values: { ...formState },
  };

  // event actions
  const hovStartEvent = hovStartToEvent();
  const hovSubmitEvent = hovSubmitToEvent();

  // event entities
  const userEvent = userContextToEvent(userData);
  const listingEvent = vehicleToListingEvent(vehicle);
  const marketingEvent = marketingToEvent(marketingDataObj);
  const listingFeatureEvent = vehicleToListingFeatureEvent(vehicle);

  const contextStart = [listingEvent, listingFeatureEvent, userEvent, marketingEvent];

  return {
    hovStart: { event: hovStartEvent, context: contextStart },
    hovSubmit: { event: hovSubmitEvent, context: contextStart },
  };
}
