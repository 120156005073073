import { Typography } from '@gds/Typography/Typography';

import styles from './PrivacyPolicyLink.module.css';

const PRIVACY_POLICY_LINKS = {
  /* eslint-disable @typescript-eslint/naming-convention */
  Honda: 'https://www.honda.co.uk/general-information/privacy-notice.html',
  Hyundai: 'https://www.hyundai.com/worldwide/en/footer/contact-legal/privacy-policy',
  Kia: 'https://www.kia.com/uk/privacy/',
  Lexus: 'https://www.lexus.co.uk/customer-data/privacy-policy',
  ['Mercedes-Benz']:
    'https://www.mercedes-benz.co.uk/passengercars/content-pool/tool-pages/legal/privacy-statement.html',
  MG: 'https://www.mg.co.uk/privacy-policy',
  Renault: 'https://www.renault.co.uk/data-privacy.html',
  SEAT: 'https://www.seat.co.uk/privacy-statement',
  Toyota: 'https://www.toyota.co.uk/footer/privacy-policy',
  Volkswagen: 'https://www.volkswagen.co.uk/en/privacy/privacy-statement.html',
  /* eslint-enable @typescript-eslint/naming-convention */
};

const BRANDS = [
  'Honda',
  'Hyundai',
  'Kia',
  'Lexus',
  'Mercedes-Benz',
  'MG',
  'Renault',
  'SEAT',
  'Toyota',
  'Volkswagen',
];

export const PrivacyPolicyLink = ({ currentBrand }: { currentBrand?: string }) => (
  <>
    {BRANDS.filter(brand => !currentBrand || brand === currentBrand).map((brand: any) => (
      <Typography key={brand} variant="body4" Component="div" className={styles.privacyPolicy}>
        {brand}{' '}
        <Typography
          variant="body4"
          Component="a"
          className={styles.termHref}
          // @ts-expect-error - href
          href={PRIVACY_POLICY_LINKS[brand]}
          target="_blank"
        >
          Privacy policy
        </Typography>
      </Typography>
    ))}
  </>
);
