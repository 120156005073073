'use client';

import { atom } from 'jotai';

import { atomFamily } from 'jotai/utils';

import { LeadFormId } from '../../Entities/Leads/LeadFormId';

export const activeLeadFormAtom = atom<LeadFormId | null>(null);

export const leadFormValidationAtom = atomFamily((_formConfigId: LeadFormId) =>
  atom(null as null | (() => Promise<void | false>)),
);
