import { OpeningTime } from '../../Entities/Dealership/Dealership.entity';

const timeStringToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

const adjustTime = (time: string, minutes: number): string => {
  let totalMinutes = timeStringToMinutes(time) + minutes;
  totalMinutes = (totalMinutes + 1440) % 1440;
  const adjustedHours = Math.floor(totalMinutes / 60);
  const adjustedMinutes = totalMinutes % 60;
  return `${String(adjustedHours).padStart(2, '0')}:${String(adjustedMinutes).padStart(2, '0')}`;
};

/**
 * Adjust the opening times by a certain amount of minutes.
 * Specifically, the opening time will be moved forward by the given amount of minutes,
 * and the closing time will be moved back by the same amount of minutes.
 *
 * For example, if the opening time is 09:00 and the closing time is 17:00,
 * and the given amount of minutes is 30, the adjusted opening time will be 09:30
 * and the adjusted closing time will be 16:30.
 */
export const bufferOpeningTimesByMinutes =
  (minutes: number) =>
  <T extends Pick<OpeningTime, 'openFrom' | 'openTo'>>(openingTimes: T) => {
    return {
      ...openingTimes,
      openFrom: adjustTime(openingTimes.openFrom, minutes),
      openTo: adjustTime(openingTimes.openTo, -minutes),
    };
  };
