'use client';

import { closeModalAtom, openModalAtom } from '@modal/Atoms/Modal.atom';
import { ModalProps } from '@modal/Entities/Modal.entity';
import { StatusDisplay } from '@shared-components/StatusDisplay/StatusDisplay';
import { atom } from 'jotai';

import { LeadType } from 'Components/Pdp/LeadCTAs/LeadCTAs.entity';
import { leadCtasConfig } from 'Components/Pdp/LeadCTAs/LeadCtaConfig';

import { leadFormIdAtom } from './LeadFormId.atom';
import { leadFormStringsAtom } from './LeadFormStrings.atom';

import statusDisplayStyles from '@shared-components/StatusDisplay/StatusDisplay.module.css';

export const openLoadingModalAtom = atom(null, (get, set, modalProps?: ModalProps) => {
  const strings = get(leadFormStringsAtom);
  const leadFormId = get(leadFormIdAtom) as LeadType;
  const isLeasingLead = leadFormId === 'leasing-online';

  // @ts-expect-error - loadingModalMessages is optional
  const messages = leadCtasConfig[leadFormId].loadingModalMessages?.(strings);
  const messageHeadline = isLeasingLead
    ? strings.loadingLeasingDescription
    : messages?.headLine ?? strings.loadingModalMessageHeadline;
  set(closeModalAtom);
  set(
    openModalAtom,
    <StatusDisplay
      messages={{
        headline: messageHeadline,
      }}
      type="loading"
    />,
    {
      header: 'Loading',
      modalAction: <></>,
      modalClassName: statusDisplayStyles.statusDisplayWrapper,
      footerClassName: statusDisplayStyles.returnActionCta,
      modalContentClassName: statusDisplayStyles.statusDisplayBodyWrapper,
      ...modalProps,
    },
  );
});
