import { TrackingEvent } from '../../../Entities/Schema.entity';

import { hovSubmitSchema } from './HovSubmitSchema';

export const hovSubmitToEvent = (): TrackingEvent<Record<any, any>> => {
  const event: TrackingEvent<Record<any, any>> = {
    schema: hovSubmitSchema.ref,
    data: {},
  };

  return event;
};
